import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import axios from "axios";

/*const beforeAuth = isAuth => (to, from, next) => {
  const isAuthenticated = store.getters['isAuthenticated']
  if( ( isAuthenticated && isAuth ) || ( !isAuthenticated && !isAuth ) ){
    return next();
  }
  else {
    // 로그인 페이지로 이동
    next({ name: 'login' });
  }
}*/

// 현재 주소
const hostname = window.location.hostname;
const pathname = window.location.pathname;
if( hostname === 'jeilfwa.dbconn.kr' ){
  document.location.href = 'http://nanumjeil.kr'+pathname;
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/2',
    name: 'home2',
    component: () => import('@/views/HomeView2.vue')
  },

  //소식
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/BoardList.vue')
  },
  {
    path: '/write',
    name: 'write',
    component: () => import('@/views/BoardWrite.vue')
  },
  {
    path: '/view',
    name: 'view',
    component: () => import('@/views/BoardView.vue')
  },
  //소식지
  {
    path: '/newsletter',
    name: 'newsletter',
    component: () => import('@/views/newsletter.vue')
  },

  //로그인, 회원가입
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/Signup.vue')
  },
  {   // 회원가입 완료 페이지
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/welcome.vue'),
    // meta: { requiresAuth: true },
  },
  // 아이디ㅣ비밀번호 찾기
  {
    path: '/findMy',
    name: 'findMy',
    component: () => import('@/views/findMy.vue')
  },

  //후원하기
  {
    path: '/login2',
    name: 'login2',
    component: () => import('@/views/Login2.vue')
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('@/views/Donate.vue')
  },
  {
    path: '/donate/:campaignId',
    name: 'donate_campaign',
    component: () => import('@/views/Donate.vue')
  },

  // 나눔박스
  {
    path: '/nanumbox_1',
    name: 'nanumbox_1',
    component: () => import('@/views/Nanumbox_1.vue')
  },
  {
    path: '/nanumbox_2',
    name: 'nanumbox_2',
    component: () => import('@/views/Nanumbox_2.vue')
  },
  {
    path: '/nanumbox_3',
    name: 'nanumbox_3',
    component: () => import('@/views/Nanumbox_3.vue')
  },
  {
    path: '/nanumbox_3/campaign_1',
    name: 'campaign_1',
    component: () => import('@/views/campaign/Campaign_1.vue')
  },
  {
    path: '/nanumbox_3/campaign_2',
    name: 'campaign_2',
    component: () => import('@/views/campaign/Campaign_2.vue')
  },
  {
    path: '/nanumbox_3/campaign_3',
    name: 'campaign_3',
    component: () => import('@/views/campaign/Campaign_3.vue')
  },

  {
    path: '/nanumbox_5',
    name: 'nanumbox_5',
    component: () => import('@/views/Nanumbox_5.vue')
  },

  // 사업소개
  {
    path: '/work_1',
    name: 'work_1',
    component: () => import('@/views/Work_1.vue')
  },
  {
    path: '/work_2',
    name: 'work_2',
    component: () => import('@/views/Work_2.vue')
  },
  {
    path: '/work_3',
    name: 'work_3',
    component: () => import('@/views/Work_3.vue')
  },
  {
    path: '/work_4',
    name: 'work_4',
    component: () => import('@/views/Work_4.vue')
  },
  {
    path: '/work_5',
    name: 'work_5',
    component: () => import('@/views/Work_5.vue')
  },
  {
    path: '/work_6',
    name: 'work_6',
    component: () => import('@/views/Work_6.vue')
  },
  // 우리 복지관은?
  {
    path: '/center_1',
    name: 'center_1',
    component: () => import('@/views/Center_1.vue')
  },
  {
    path: '/center_2',
    name: 'center_2',
    component: () => import('@/views/Center_2.vue')
  },
  {
    path: '/center_3',
    name: 'center_3',
    component: () => import('@/views/Center_3.vue')
  },
  {
    path: '/center_4',
    name: 'center_4',
    component: () => import('@/views/Center_4.vue')
  },
  // 이용약관
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service.vue'),
  },
  // 개인정보처리방침
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy.vue'),
  },
  // 개인정보처리방침
  {
    path: '/email',
    name: 'email',
    component: () => import('@/views/email.vue'),
  },  
  // 마이페이지
  {
    path: '/myinfo',
    name: 'myinfo',
    component: () => import('@/views/mypage/myinfo.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mysupport',
    name: 'mysupport',
    component: () => import('@/views/mypage/mysupport.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/myqna',
    name: 'myqna',
    component: () => import('@/views/mypage/myqna.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/myqna/:qna_idx',
    name: 'myqna_view',
    component: () => import('@/views/mypage/myqna_view.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/mywithdraw',
    name: 'mywithdraw',
    component: () => import('@/views/mypage/mywithdraw.vue'),
    meta: { requiresAuth: true },
  },

  // 게시판 관련 링크
  { // 글 목록
    path: '/list/:board_id',
    name: 'board_list',
    component: () => import('@/views/BoardList.vue'),
  },
  { // 글 조회
    path: '/view/:board_id/:post_id',
    name: 'board_view',
    component: () => import('@/views/BoardView.vue'),
  },
  { //글 쓰기
    path: '/write/:board_id',
    name: 'board_write',
    component: () => import('@/views/BoardWrite.vue'),
  },
  { //글 쓰기
    path: '/modify/:board_id/:post_id',
    name: 'board_modify',
    component: () => import('@/views/BoardWrite.vue'),
  },

  {
    path: '/jeilBoardCheck',
    name: 'jeilBoardCheck',
    component: () => import('@/components/JeilBoardCheckComponent.vue'),
  },



  // 관리자 관련 링크 ---------------------------------------------------------------------------
  {
    path: '/admin_login',
    name: 'admin_login',
    component: () => import('@/views/admin_login.vue')
  },

  {
    path: "/admin",
    // redirect: "/admin/dashboard",
    component: () => import('@/layouts/Admin.vue'),
    children: [
      {
        path: "/admin/dashboard",
        component: () => import('@/layouts/Admin.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/member",
        component: () => import('@/views/adm/member.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/board",
        component: () => import('@/views/adm/board.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/donate",
        component: () => import('@/views/adm/donate.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/donate/:donate_id",
        component: () => import('@/views/adm/donateDetail.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/popup",
        component: () => import('@/views/adm/popup.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/qna",
        component: () => import('@/views/adm/qna.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: "/admin/qna/:qna_idx",
        component: () => import('@/views/adm/qnaView.vue'),
        meta: { requiresAuth: true },
      },
      /*
      {
        path: "/admin/settings",
        component: Settings,
      },
      {
        path: "/admin/tables",
        component: Tables,
      },
      {
        path: "/admin/maps",
        component: Maps,
      },
      */
    ],
  },

]



const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(){
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // TODO: 로그인 여부 확인
  // console.log(to.meta.requiresAuth);
  // console.log(to.meta);
  /*console.log(to);
  console.log(from);
  console.log(next);*/

  // this.$store.dispatch('checkAuth');

  // 라우터 가드
  if( to.matched.some( record => record.meta.requiresAuth ) ){
    const url = "/api/member.php"
    const formData = new FormData;
    formData.append('mode', 'checkAuth');
    try {
      axios.post(url,formData).then(function(res) {
        // console.log(res.data);
        if( res.data.err === 0 ){
          store.commit('login', {
            access_token: res.data.access_token,
            user_info: res.data.user_info,
          });
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('isAuthenticated', true);

          store.dispatch('getMyInfo');

          next();
        }
        else {
          alert('로그인 후 이용 가능 합니다.');
          next({ name: 'login' });
        }
      })
    } catch (e) {
      console.log(e);
      alert('로그인 후 이용 가능 합니다.');
      next({ name: 'login' });
    }
  }
  else {
    // store.dispatch('getMyInfo').then(r => (console.log(r)));
    const token = localStorage.getItem('access_token');
    if( token ){
      store.dispatch('getMyInfo');
    }
    next();
  }
})


export default router
