import { createStore } from 'vuex'
import axios from "axios";


const store = createStore({
  state: {
    token: {
      access_token: "",
    },
    isAuthenticated: false,
    user_info:{
      idx: "",
      uid: "",
      name: "",
      contact1: "",
      contact2: "",
      contact3: "",
      phone: "",
      email_domain: "",
      email_id: "",
      email: "",
      postcode: "",
      address: "",
      address_detail: "",
      interest: "",
      level: 0,
      reg_date: ""
    },
    jeilBoardCheck: '',
  },
  // state 값을 가져오는 역할, computed와 비슷, getters: { 함수명(state) { return state.변수명 } }
  getters: {
    getAccessToken(state) {
      return state.token.access_token
    },
    getMyInfo(state) {
      return state.user_info
    },
    isAuthenticated(state) {
      return state.isAuthenticated
    },
    getJeilBoardCheck(state) {
      return state.jeilBoardCheck
    }
  },
  // state 변경 시키는 역할, 동기처리, commit('함수명', '전달인자'), 함수 형태로 작성
  mutations: {
    logout(state, payload = {}) {
      state.token.access_token = "";
      state.isAuthenticated = false;
      state.user_info = {};
      localStorage.removeItem('access_token');
    },
    login(state, payload) {
      state.token.access_token = payload.access_token;
      localStorage.setItem('access_token', payload.access_token);
      // state.user_info = payload.user_info;
      state.isAuthenticated = true;
    },
    setMyInfo(state, payload) {
      state.user_info = payload.user_info;
    },
    setJeilBoardCheck(state, payload) {
      console.log(state);
      console.log(payload);
      state.jeilBoardCheck = payload.jeilBoardCheck;
    }
  },
  // mutations 실행 하는 역할, 비동기처리, dispatch('함수명', '전달인자'), 함수 형태로 작성
  actions: {
    logout(context, payload) {
      return new Promise(resolve => {
        setTimeout(() => {
            context.commit('logout', payload)
            resolve({})
        }, 1000)
      })
    },
    checkAuth: async function (context, payload) {
      const url = "/api/member.php"
      const formData = new FormData;
      formData.append('mode', 'checkAuth');
      try {
        await axios.post(url,formData).then(function(res) {
          // console.log(res.data);
          if( res.data.err === 0 ){
            context.commit('login', {access_token: res.data.access_token});
            localStorage.setItem('access_token', res.data.access_token);
            localStorage.setItem('isAuthenticated', true);
          }
          /*
          if( res.data.err === 0 ){
            // 토큰 저장
            // localStorage.setItem('access_token', res.data.access_token);
            context.commit('login', {
              access_token: res.data.access_token,
            });

            if( payload.rememberMe === true ){
              localStorage.setItem('saveId', payload.username);
            }
            // me.$router.push('/');
            me.$router.push('/');
          }
          else {
            // 토큰 삭제
            alert(res.data.err_msg);
            // me.$delItemLocalStoage("authorization");
            localStorage.removeItem("access_token");
          }
          */
        })
      } catch (e) {
        // console.log(e);
      }
    },
    login: function (context, payload) {
      /*console.log(context);
      console.log(payload);*/

      const me = this;
      const url = "/api/member.php"
      const formData = new FormData;
      formData.append('mode', 'login');
      formData.append('username', payload.username);
      formData.append('password', payload.password);
      try {
        this.axios.post(url,formData).then(function(res) {
          if( res.data.err === 0 ){
            // 토큰 저장
            // localStorage.setItem('access_token', res.data.access_token);
            context.commit('login', {
              access_token: res.data.access_token,
            });

            if( payload.rememberMe === true ){
              localStorage.setItem('saveId', payload.username);
            }
            // me.$router.push('/');
            me.$router.push('/');
          }
          else {
            // 토큰 삭제
            alert(res.data.err_msg);
            // me.$delItemLocalStoage("authorization");
            localStorage.removeItem("access_token");
          }
        })
      } catch (e) {
        console.log(e);
      }
    },

    getMyInfo: async function (context, payload) {
      try {
        const url = "/api/member.php"
        const formData = new FormData;
        formData.append('mode', 'getMyInfo');
        await axios.post(url,formData).then(function(res) {
          if( res.data.err === 0 ){
            // 토큰 저장
            // localStorage.setItem('access_token', res.data.access_token);
            context.commit('setMyInfo', { user_info: res.data.my_info });
            context.commit('login', { access_token: res.data.access_token });
          }
          else {
            // 토큰 삭제
            // alert(res.data.err_msg);
            context.commit('logout');
          }
        })
      } catch (e) {
        console.log(e);
      }
    }
  },
  modules: {
  }
})


export default store
