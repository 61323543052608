<template>
  
  <router-view/>

</template>

<script>
import { notify } from 'vuetensils/src/components/VNotifications/VNotifications.vue'

export default {
  name: 'App',
  components: {

  },

  setup() {
    return {
      notify
    }
  },

  methods: {
    notify
  }
}
</script>
